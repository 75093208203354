import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: '/images/Anusha.jpeg',
      testimonialText:
        'I had an exceptional experience with Twizzle that exceeded my expectations. Their meticulous attention to detail is truly impressive. I can confidently assert that my podcast wouldn\'t have reached its audience without the dedicated efforts of Harsh, Shubham, Abhay, and the entire Twizzle Studio team. Looking forward to working with them again!',
      avatarName: 'Anusha Maheshwari',
      avatarDesignation: ' Founder Lemon Lessons with Anusha',
      ratings: '5',
    },
    {
      testimonialThumb: '/images/sunny.jpeg',
      testimonialText:
        'What sets Twizzle Media House apart is not just their technical proficiency but also their dedication to customer satisfaction. The team was communicative throughout the entire process, ensuring that I was involved in decision-making and that my feedback was valued. Their collaborative approach made the entire experience enjoyable and stress-free.        ',
      avatarName: 'Sunny garg',
      avatarDesignation: 'CEO AT Crib',
      ratings: '4.5',
    },
    {
      testimonialThumb: '/images/rishitabh.jpeg',
      testimonialText:
        'I wholeheartedly recommend Twizzle Media for anyone seeking professional video production services. Their commitment to excellence, combined with their creativity and communication skills, make them a standout choice.        ',
      avatarName: 'Rishitabh Thapliyal      ',
      avatarDesignation: 'CEO AT Campus Binge',
      ratings: '5',
    },
    
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
