import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";
import Spacing from "../Spacing";
import Cta from "../Cta";
import LogoList from "../LogoList";
import MovingText from "../MovingText";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const copyrightLinks = [
    {
      title: "Terms of Use",
      href: "/",
    },
    {
      title: "Privacy Policy",
      href: "/",
    },
  ];

  const serviceMenu = [
    {
      title: "Media production",
      href: "../contact",
    },
    {
      title: "Studio services",
      href: "../contact",
    },
    {
      title: "content creation",
      href: "../contact",
    },
    {
      title: "content promotion",
      href: "../contact",
    },
  ];

  return (
    <>
      

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss and make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      
      <footer className="cs-fooer">
        <Div className="cs-fooer_main">
          <Div className="container">
            <Div className="row">
              <Div className="col-lg-3 col-sm-6">
                <Div className="cs-footer_item">
                  <TextWidget
                    logoSrc="/images/logo.png"
                    logoAlt="Logo"
                    text="Twizzle Media House- From content creation to content promotion we have you covered"
                  />
                  <SocialWidget />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6">
                <Div className="cs-footer_item">
                  <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6">
                <Div className="cs-footer_item">
                  <ContactInfoWidget title="Contact Us" />
                </Div>
              </Div>
              <Div className="col-lg-3 col-sm-6">
                <Div className="cs-footer_item">
                  <Newsletter
                    title="Subscribe"
                    subtitle="Drop your mail and get weekly updates
                  on running offers and exclusive service packages."
                    placeholder="example@gmail.com"
                  />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Div className="container">
          <Div className="cs-bottom_footer">
            <Div className="cs-bottom_footer_left">
              <Div className="cs-copyright">Copyright © 2024 Windikate.</Div>
            </Div>
            <Div className="cs-bottom_footer_right">
              <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
            </Div>
          </Div>
        </Div>
      </footer>
    </>
  );
}
