import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Button from "../Button";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

export default function PortfolioDetailsPage() {
  const params = useParams();
  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const id = params.portfolioDetailsId;

  if (id === "mercedes") {
    return (
      <>
        <PageHeading
          title="Portfolio Details"
          bgSrc="/images/service_hero_bg.jpeg"
          pageLinkText={params.portfolioDetailsId}
        />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title="Capturing Excellence: Mercedes Car Launch Event Coverage"
                subtitle="Twizzle Media House"
              >
                <Spacing lg="40" md="20" />
                <p>
                  Step into the world of luxury and innovation as Twizzle Media
                  House presents a seamless blend of professionalism and
                  artistry in our event coverage project. We had the privilege
                  of documenting the unveiling of Mercedes' latest masterpiece,
                  providing comprehensive photography and videography services.
                  Our lens not only captured the sleek lines and cutting-edge
                  features of the newly launched car but also encapsulated the
                  essence of the event itself.
                </p>
                <Spacing lg="10" md="10" />
                <p>
                  Immerse yourself in the visual narrative that goes beyond
                  documentation, telling the story of an extraordinary moment in
                  automotive history. Explore the epitome of sophistication
                  through our lens – where every frame is a testament to
                  precision and prestige.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Category:
                  </h3>
                  <p className="cs-m0">Event Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Location:
                  </h3>
                  <p className="cs-m0">Bangalore</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Service:
                  </h3>
                  <p className="cs-m0">Photography & Videography</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Work for:
                  </h3>
                  <p className="cs-m0">Mercedes</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <img
            src={`/images/${params.portfolioDetailsId}/2.jpg`}
            alt="Details"
            className="w-100"
          />

          {/* <Spacing lg="10" md="10" /> */}
          <img
            src={`/images/${params.portfolioDetailsId}/1.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/3.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/6.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/5.jpg`}
            alt="Details"
            className="w-50"
          />
          <Spacing lg="90" md="40" />
        </Div>
      </>
    );
  }
  if (id === "xpulse") {
    return (
      <>
        <PageHeading
          title="Portfolio Details"
          bgSrc="/images/service_hero_bg.jpeg"
          pageLinkText={params.portfolioDetailsId}
        />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title="Unleashing the Thrill: Hero Xpulse Motorsport Event Coverage"
                subtitle="Twizzle Media House"
              >
                <Spacing lg="40" md="20" />
                <p>
                  Embark on an adrenaline-fueled journey as Twizzle Media House
                  brings you an exclusive glimpse into the heart-pounding world
                  of motorsport. Our lens captured the dynamic essence of the
                  Hero Xpulse dirt bike at a high-octane motorsport event
                  organized by Hero. Specializing in professional photography
                  services, our team expertly documented the raw power,
                  precision, and excitement of the race.
                </p>
                <Spacing lg="10" md="10" />
                <p>
                  Each shot encapsulates the spirit of Hero Xpulse, revealing
                  the intensity and thrill that define the motorsport
                  experience. Join us as we take you beyond the finish line,
                  showcasing a visual narrative that echoes the passion and
                  innovation of Hero's dirt bike prowess.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Category:
                  </h3>
                  <p className="cs-m0">Event Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Location:
                  </h3>
                  <p className="cs-m0">Bangalore</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Service:
                  </h3>
                  <p className="cs-m0">Photography & Drone Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Work for:
                  </h3>
                  <p className="cs-m0">Hero</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <img
            src={`/images/${params.portfolioDetailsId}/2.jpg`}
            alt="Details"
            className="w-100"
          />

          {/* <Spacing lg="10" md="10" /> */}
          <img
            src={`/images/${params.portfolioDetailsId}/1.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/3.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/4.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/6.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/5.jpg`}
            alt="Details"
            className="w-100"
          />
          <Spacing lg="90" md="40" />
        </Div>
      </>
    );
  }
  if (id === "ktm") {
    return (
      <>
        <PageHeading
          title="Portfolio Details"
          bgSrc="/images/service_hero_bg.jpeg"
          pageLinkText={params.portfolioDetailsId}
        />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title="Racing into Euphoria: KTM Motorsport Event Coverage"
                subtitle="Twizzle Media House"
              >
                <Spacing lg="40" md="20" />
                <p>
                  Experience the roar of engines and the thrill of speed through
                  the lens of Twizzle Media House as we delve into the heart of
                  a mesmerizing motorsport event organized by KTM. Specializing
                  in professional photography services, we've skillfully
                  captured the dynamic essence of KTM's racing prowess.
                </p>
                <Spacing lg="10" md="10" />
                <p>
                  Our work not only showcases the high-speed intensity of the
                  event but also immortalizes the precision, power, and
                  adrenaline that define the KTM experience. Immerse yourself in
                  a visual journey that encapsulates the essence of motorsport
                  excellence – where every frame tells a story of passion,
                  speed, and unwavering commitment to performance.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Category:
                  </h3>
                  <p className="cs-m0">Event Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Location:
                  </h3>
                  <p className="cs-m0">Bangalore</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Service:
                  </h3>
                  <p className="cs-m0">Photography</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Work for:
                  </h3>
                  <p className="cs-m0">KTM</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <img
            src={`/images/${params.portfolioDetailsId}/1.jpg`}
            alt="Details"
            className="w-100"
          />

          {/* <Spacing lg="10" md="10" /> */}
          <img
            src={`/images/${params.portfolioDetailsId}/2.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/3.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/4.png`}
            alt="Details"
            className="w-50"
          />
          <Spacing lg="90" md="40" />
        </Div>
      </>
    );
  }

  if (id === "jeep") {
    return (
      <>
        <PageHeading
          title="Portfolio Details"
          bgSrc="/images/service_hero_bg.jpeg"
          pageLinkText={params.portfolioDetailsId}
        />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title="Trailblazing Elegance: Jeep Car Event Coverage"
                subtitle="Twizzle Media House"
              >
                <Spacing lg="40" md="20" />
                <p>
                  Embark on a visual journey with Twizzle Media House as we
                  present our impeccable event coverage project capturing the
                  essence of the Jeep car event. Specializing in professional
                  photography services, our lens documented the dynamic allure
                  and rugged elegance of Jeep's iconic vehicles. Each shot
                  encapsulates the power, design, and adventure that define the
                  Jeep experience.
                </p>
                <Spacing lg="10" md="10" />
                <p>
                  From the gleaming exteriors to the adrenaline-pumping moments
                  on the trail, our work brings to life the spirit of this
                  automotive extravaganza. Immerse yourself in the world of
                  Jeep, where professionalism meets the thrill of the open road,
                  through our lens crafted to perfection.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Category:
                  </h3>
                  <p className="cs-m0">Event Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Location:
                  </h3>
                  <p className="cs-m0">Bangalore</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Service:
                  </h3>
                  <p className="cs-m0">Photography</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Work for:
                  </h3>
                  <p className="cs-m0">JEEP</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <img
            src={`/images/${params.portfolioDetailsId}/1.jpg`}
            alt="Details"
            className="w-100"
          />

          {/* <Spacing lg="10" md="10" /> */}
          <img
            src={`/images/${params.portfolioDetailsId}/2.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/3.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/4.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/5.jpg`}
            alt="Details"
            className="w-50"
          />
          <Spacing lg="90" md="40" />
        </Div>
      </>
    );
  }

  if (id === "crib") {
    return (
      <>
        <PageHeading
          title="Portfolio Details"
          bgSrc="/images/service_hero_bg.jpeg"
          pageLinkText={params.portfolioDetailsId}
        />
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-6">
              <SectionHeading
                title="Moments of Togetherness: Crib Founding Team's Onsite Vacation Captured"
                subtitle="Twizzle Media House"
              >
                <Spacing lg="40" md="20" />
                <p>
                  Join us on a visual journey as Twizzle Media House turns the
                  lens toward the heartwarming onsite vacation of Crib’s
                  founding team. Our photography project beautifully documents
                  the camaraderie, laughter, and shared moments that define this
                  special getaway.
                </p>
                <Spacing lg="10" md="10" />
                <p>
                  From candid shots that capture the genuine connections among
                  team members to scenic landscapes that frame the backdrop of
                  their retreat, our work immortalizes the essence of this
                  memorable occasion.
                </p>
              </SectionHeading>
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <Spacing lg="60" md="40" />
              <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
              <Spacing lg="50" md="30" />
              <Div className="row">
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Category:
                  </h3>
                  <p className="cs-m0">Event Coverage</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Location:
                  </h3>
                  <p className="cs-m0">Goa</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Service:
                  </h3>
                  <p className="cs-m0">Photography</p>
                  <Spacing lg="30" md="30" />
                </Div>
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Work for:
                  </h3>
                  <p className="cs-m0">Crib</p>
                  <Spacing lg="30" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
          <img
            src={`/images/${params.portfolioDetailsId}/1.jpg`}
            alt="Details"
            className="w-100"
          />

          {/* <Spacing lg="10" md="10" /> */}
          <img
            src={`/images/${params.portfolioDetailsId}/2.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/3.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/4.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/5.jpg`}
            alt="Details"
            className="w-50"
          />
          <img
            src={`/images/${params.portfolioDetailsId}/6.jpg`}
            alt="Details"
            className="w-100"
          />
          <Spacing lg="90" md="40" />
        </Div>
      </>
    );
  }
}
