import React, { useEffect, useState } from "react";
import Cta from "../Cta";
import Div from "../Div";
import Hero5 from "../Hero/Hero5";
import LogoList from "../LogoList";
import MovingText from "../MovingText";
import Spacing from "../Spacing";
import VideoModal from "../VideoModal";
import { pageTitle } from "../../helper";
import PostSlider from "../Slider/PostSlider";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import PricingTableList from "../PricingTable/PricingTableList";
import FunFact2 from "../FunFact/FunFact2";
import PortfolioSlider2 from "../Slider/PortfolioSlider2";
import ServiceList from "../ServiceList";
import MovingText2 from "../MovingText/MovingText2";
import Portfolio from "../Portfolio";
import { Icon } from '@iconify/react';

export default function MarketingAgencyHome() {
  pageTitle("Twizzle Media");
  const [itemShow, setItemShow] = useState(2);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: "Behance",
      links: "/",
    },
    {
      name: "Twitter",
      links: "/",
    },
  ];
  const funfaceData = [
    {
      title: "Digital products",
      factNumber: "550",
    },
    {
      title: "Global happy clients",
      factNumber: "40K",
    },
    {
      title: "Project completed",
      factNumber: "50k",
    },
    {
      title: "Team members",
      factNumber: "250",
    },
  ];

  const serviceData1 = [
    {
      title: "Content Creation",
      href: "../contact",
    },
    {
      title: "Studio Services",
      href: "../contact",
    },
    {
      title: "Content Strategy",
      href: "../contact",
    },
  ];

  const serviceData2 = [
    {
      title: "Content Marketing",
      href: "../contact",
    },
    {
      title: "Content Distribution",
      href: "../contact",
    },
    {
      title: "Custom Solutions",
      href: "../contact",
    },
  ];

  const portfolioData = [
    {
      title: 'Mercedes',
      subtitle: 'See Details',
      href: '/portfolio/mercedes',
      src: '/images/mercedes/2.jpg',
      category: 'ui_ux_design',
    },
    {
      title: 'Hero Xpulse',
      subtitle: 'See Details',
      href: '/portfolio/xpulse',
      src: '/images/xpulse/1.jpg',
      category: 'logo_design',
    },
    {
      title: 'KTM',
      subtitle: 'See Details',
      href: '/portfolio/ktm',
      src: '/images/ktm/1.jpg',
      category: 'logo_design',
    },
    {
      title: 'Jeep',
      subtitle: 'See Details',
      href: '/portfolio/jeep',
      src: '/images/jeep/1.jpg',
      category: 'logo_design',
    },
    {
      title: 'crib',
      subtitle: 'See Details',
      href: '/portfolio/crib',
      src: '/images/crib/6.jpg',
      category: 'logo_design',
    },
  ];
  

  return (
    <>
      {/* Start Hero Section */}
      <Hero5
        title="Grow Your Business<br /> With Digital Strategy"
        subtitle="From content creation to content promotion we got you covered on all aspects."
        btnLink="../contact"
        btnText=""
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our core services"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

      {/* Start Projects Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Portfolio to explore"
          subtitle="Latest Projects"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 0 || index === 3 || index === 4
                  ? "col-lg-8"
                  : "col-lg-4"
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      {/* End Projects Section */}

      {/* Start MovingText Section */}
      <Spacing lg="125" md="70" />
      <MovingText text="Our reputed world wide partners" />
      <Spacing lg="100" md="70" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="130" md="80" />
      {/* End LogoList Section */}

      
    </>
  );
}
